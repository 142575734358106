@font-face {
  font-family: 'PSL-LOGO';
  src: url('resources/fonts/PSL051.TTF');
}

@font-face {
  font-family: 'PSL';
  src: url('resources/fonts/PSL-Display Regular.ttf');
}

@font-face {
  font-family: 'Prompt-Regular';
  src: url('resources/fonts/Prompt-Regular.ttf');
}

@font-face {
  font-family: 'Prompt-Bold';
  src: url('resources/fonts/Prompt-Bold.ttf');
}

@font-face {
  font-family: 'Prompt-Medium';
  src: url('resources/fonts/Prompt-Medium.ttf');
}

@font-face {
  font-family: 'SNT-Anouvong-Regular';
  src: url('resources/fonts/SNT Anouvong Regular.otf');
}

@font-face {
  font-family: 'SNT-Anouvong-Bold';
  src: url('resources/fonts/SNT Anouvong Bold Regular.otf');
}

@font-face {
  font-family: 'SNT-Anouvong-Medium';
  src: url('resources/fonts/SNT Anouvong Medium Regular.otf');
}

body {
  font-weight: 400;
  font-family: 'SNT-Anouvong-Regular';
  font-size: 20px;
}

.period td,
.period th {
  padding: .25rem !important;
}

.award-current-box {
  z-index: 2;
  background: #fff;
  border-radius: 10px;
  padding: 1rem;
}

.footer {
  width: 100%;
  background-color: #0077B6;
  color: white;
  text-align: center;
  font-size: 16px !important;
}

.announce.table-bordered td {
  text-align: center;
  vertical-align: middle;
  background-color: #EBEBEB !important;
  padding: 6px;
}

.announce thead th {
  background-color: #EBEBEB;
  font-weight: normal;
  /* color: white; */
}

.logo-gradient {
  background: #ecbb1e;
  background: radial-gradient(circle farthest-side at center center, #ecbb1e 0%, #C37C17 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 1000;

}